import TiktokRender from "./TiktokRender";
import { useNavigate } from "react-router-dom";
import { STYLE_CARDS_PORTFOLIO } from "../../../lib/style";
import { ICON_DELETE } from "../../../lib/icons";
import { FunctionGetPathUrl } from "../../../utils/useFunctions";
import api from "../../../api";
import useSweetAlert from "../../../utils/useSweetAlert";
import { useEffect, useState } from "react";
import YoutubeRender from "./YoutubeRender";
import FacebookRender from "./FacebookRender";
import { DeleteButton } from "../../../components/attributes/Button";

const PortfolioCard = ({ portfolio, index }) => {
  const [portfolioType, setPortfolioType] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const navigate = useNavigate();
  const { confirm, successDeleteAlert } = useSweetAlert();

  console.log("portfolio", portfolio);

  // useEffect(() => {
  //   LoadData();
  // }, [portfolioType]);

  // const LoadData = async () => {
  //   setLoading(true);
  //   try {
  //     let query = "";
  //     if (portfolioType) {
  //       query = `?type=${portfolioType}`;
  //     }
  //     const { data } = await api.get(`/portfolios${query}`);
  //     setData(data);
  //   } catch (error) {
  //     console.error("ERROR TO LOAD PORTFOLIO : ", error);
  //   }
  // };

  const clickDeleteCard = async (e, id) => {
    e.stopPropagation();
    await confirm({
      title: "คุณต้องการลบการ์ดนี้หรือไม่",
      callback: () => onClickToDeleteCard(id),
    });
  };

  const onClickToDeleteCard = async (id) => {
    try {
      await api.delete(`/portfolios/${id}`);
      navigate("/Portfolio");
      successDeleteAlert();
      // LoadData();
      window.location.reload();
    } catch (error) {
      console.error("ERROR TO DELETE PORTFOLIO : ", error);
    }
  };

  const { type } = portfolio;

  // if (type === "tiktok") {
  //   return (
  //     <TiktokRender
  //       portfolio={portfolio}
  //       index={index}
  //       clickDeleteCard={clickDeleteCard}
  //     />
  //   );
  // }

  if (type === "youtube") {
    return (
      <YoutubeRender
        portfolio={portfolio}
        index={index}
        clickDeleteCard={clickDeleteCard}
      />
    );
  }

  if (type === "facebook") {
    return (
      <FacebookRender
        portfolio={portfolio}
        index={index}
        clickDeleteCard={clickDeleteCard}
      />
    );
  }

  return (
    <div
      onClick={() => {
        navigate(`/Portfolio/${portfolio?._id}`);
      }}
      key={index}
    >
      <img
        className=" w-full h-56 object-cover"
        src={FunctionGetPathUrl(portfolio?.image_key)}
      />

      <div className=" text-xl flex my-3">{portfolio?.title}</div>
      <div className=" font-light pt-1 line-clamp-2 px-3">
        {portfolio?.description}
      </div>
      <DeleteButton onClick={(e) => clickDeleteCard(e, portfolio?._id)} />
    </div>
  );
};

export default PortfolioCard;
