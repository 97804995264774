import { useNavigate } from "react-router-dom";
import {
  FacebookIcon,
  ICON_DELETE,
  InstagramIcon,
  TiktokIcon,
  TwitterIcon,
  UserReview,
  YoutubeIcon,
} from "../../../lib/icons";
import {
  STYLE_ADD_BUTTON,
  STYLE_CARDS_PORTFOLIO,
  STYLE_GRID_PORTFOLIO,
  STYLE_HEAD_PORTFOLIO,
} from "../../../lib/style";
import { FunctionGetPathUrl } from "../../../utils/useFunctions";
import useSweetAlert from "../../../utils/useSweetAlert";
import Iframe from "react-iframe";
import PortfolioCard from "./PortfolioCard";
import { Button, DeleteButton } from "../../../components/attributes/Button";
import { FunctionGetYouTubeId } from "radium-omnibus";
import React from "react";
import api from "../../../api";

const EditIndexElement = ({ data, refetch = () => {} }) => {
  const [index, setIndex] = React.useState(-1);
  const [edit, setEdit] = React.useState(false);

  React.useEffect(() => {
    if (data) {
      setIndex(data?.index);
    }
  }, [data]);

  const FunctionUpdateIndexToApi = async () => {
    try {
      await api.put(`/portfolios/${data?._id}`, {
        index: index,
      });
      window.location.reload();
      setEdit(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div
      className="flex justify-between border-t border-separate mt-6"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <input
        className="w-20 text-center border rounded-md h-12 my-auto border-glowon-textgray"
        disabled={!edit}
        value={index}
        onChange={({ target: { value } }) => {
          setIndex(value);
        }}
      />
      {edit ? (
        <div className="flex justify-end my-5">
          <button
            type="button"
            className={STYLE_ADD_BUTTON}
            onClick={(e) => {
              e.stopPropagation();
              FunctionUpdateIndexToApi();
              // refetch();
            }}
          >
            Update
          </button>
        </div>
      ) : (
        <div className="flex justify-end my-5">
          <button
            type="button"
            className={STYLE_ADD_BUTTON}
            onClick={(e) => {
              e.stopPropagation();

              setEdit(true);
            }}
          >
            Edit
          </button>
        </div>
      )}
    </div>
  );
};

export const PortfolioSection = ({
  data,
  onClickToDeleteCard,
  portfolioType,
}) => {
  const { confirm } = useSweetAlert();
  const navigate = useNavigate();

  const clickDeleteCard = async (e, id) => {
    e.stopPropagation();
    await confirm({
      title: "คุณต้องการลบการ์ดนี้หรือไม่",
      callback: () => onClickToDeleteCard(id),
    });
  };

  if (portfolioType === "youtube") {
    const dataMini = data?.portfolios?.filter(
      (item) => item?.radio === "mini_review"
    );
    const dataBuzz = data?.portfolios?.filter(
      (item) => item?.radio === "buzz_shout_out"
    );

    return (
      <div className=" ">
        <div className={STYLE_HEAD_PORTFOLIO}>
          <YoutubeIcon />
          Youtube (vdo on facebook)
        </div>
        <div className={STYLE_GRID_PORTFOLIO}>
          {dataMini?.map((row, index) => {
            return (
              <div
                onClick={() => {
                  navigate(`/Portfolio/${row?._id}`);
                }}
                key={index}
                className={STYLE_CARDS_PORTFOLIO}
              >
                <div className=" ">
                  <img
                    className=" w-full h-56 object-cover "
                    src={FunctionGetPathUrl(row?.image_key)}
                  />
                </div>
                <div className="flex-1">
                  <div className=" text-xl  pt-2 px-3 flex my-3">
                    {row?.title}
                  </div>
                  <div className="  font-light pt-1 line-clamp-2  px-3 ">
                    {row?.description}
                  </div>
                </div>
                <DeleteButton onClick={(e) => clickDeleteCard(e, row?._id)} />
                <EditIndexElement data={row} />
              </div>
            );
          })}
        </div>

        <div className={STYLE_HEAD_PORTFOLIO}>
          <YoutubeIcon />
          Youtube (Vdo on youtube)
        </div>
        <div className={STYLE_GRID_PORTFOLIO}>
          {dataBuzz?.map((row, index) => {
            return (
              <div
                onClick={() => {
                  navigate(`/Portfolio/${row?._id}`);
                }}
                key={index}
                className={STYLE_CARDS_PORTFOLIO}
              >
                <div className=" h-56 object-cover ">
                  <Iframe
                    url={
                      row?.link?.slice(0, 5) === "https"
                        ? `https://www.youtube.com/embed/${FunctionGetYouTubeId(
                            row?.link
                          )}`
                        : `https://www.youtube.com/embed/${row?.link}`
                    }
                    width="100%"
                    height="100%"
                    display="initial"
                    position="relative"
                  />
                </div>
                <div className="flex-1">
                  <div className=" text-xl  pt-2 px-3 flex my-3">
                    {row?.title}
                  </div>
                  <div className="  font-light pt-1 line-clamp-2  px-3 ">
                    {row?.description}
                  </div>
                </div>
                <DeleteButton onClick={(e) => clickDeleteCard(e, row?._id)} />
                <EditIndexElement data={row} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  if (portfolioType === "facebook") {
    const dataMini = data?.portfolios?.filter(
      (item) => item?.radio === "mini_review"
    );

    const dataBuzz = data?.portfolios?.filter(
      (item) => item?.radio === "buzz_shout_out"
    );

    return (
      <div>
        <div className={STYLE_HEAD_PORTFOLIO}>
          <FacebookIcon className="w-8" />
          Facebook (mini review)
        </div>
        <div className={STYLE_GRID_PORTFOLIO}>
          {dataMini?.map((row, index) => {
            return (
              <div
                className={STYLE_CARDS_PORTFOLIO}
                onClick={() => {
                  navigate(`/Portfolio/${row?._id}`);
                }}
                key={index}
                // className="w-96 h-auto ml-10"
              >
                <img
                  className=" w-full h-56 object-cover "
                  src={FunctionGetPathUrl(row?.image_key)}
                />
                <div className="flex-1">
                  <div className=" text-xl flex my-3 break-all">
                    {row?.title}
                  </div>
                  <div className=" font-lightline-clamp-2 ">
                    {row?.description}
                  </div>
                </div>
                <DeleteButton onClick={(e) => clickDeleteCard(e, row?._id)} />
                <EditIndexElement data={row} />
              </div>
            );
          })}
        </div>

        <div className={STYLE_HEAD_PORTFOLIO}>
          <FacebookIcon className="w-8" />
          Facebook (buzz shout out)
        </div>
        <div className={STYLE_GRID_PORTFOLIO}>
          {dataBuzz?.map((row, index) => {
            return (
              <div
                onClick={() => {
                  navigate(`/Portfolio/${row?._id}`);
                }}
                key={index}
                className={STYLE_CARDS_PORTFOLIO}
              >
                <img
                  className=" w-full h-56 mx-auto object-cover "
                  src={FunctionGetPathUrl(row?.image_key || row?.received)}
                />
                <div className="flex-1">
                  <div className=" text-xl my-3 ">{row?.title}</div>
                  <div className="  font-light pt-1 line-clamp-2  ">
                    {row?.description}
                  </div>
                </div>
                <DeleteButton onClick={(e) => clickDeleteCard(e, row?._id)} />
                <EditIndexElement data={row} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  const Title = () => {
    if (portfolioType === "twitter") {
      return (
        <div className={STYLE_HEAD_PORTFOLIO}>
          <TwitterIcon className="w-8" />
          Twitter Influencers (mini review)
        </div>
      );
    }
    if (portfolioType === "user_review") {
      return (
        <div className={STYLE_HEAD_PORTFOLIO}>
          <UserReview className="w-8" />
          User reviews (jeban,pantip,cosmenet)
        </div>
      );
    }
    if (portfolioType === "instagram") {
      return (
        <div className={STYLE_HEAD_PORTFOLIO}>
          <InstagramIcon className="w-8" />
          instagram (buzz shout out)
        </div>
      );
    }
    if (portfolioType === "tiktok") {
      return (
        <div className={STYLE_HEAD_PORTFOLIO}>
          <TiktokIcon className="w-8" />
          Tiktok (vdo review)
        </div>
      );
    }
  };

  return (
    <div>
      <Title />
      <div className="grid grid-cols-3 gap-6 my-4">
        {data?.portfolios?.map((portfolio, index) => {
          return (
            <div className="">
              <div
                onClick={() => {
                  navigate(`/Portfolio/${portfolio?._id}`);
                }}
                key={index}
                className={STYLE_CARDS_PORTFOLIO}
              >
                <PortfolioCard portfolio={portfolio} index={index} />
                <EditIndexElement data={portfolio} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default PortfolioSection;
